import type { FunctionalComponent } from 'preact';

import { Button } from 'src/shared/components/button/button.js';
import { HtmlText } from 'src/shared/components/html-text/html-text.js';
import { MultilineText } from 'src/shared/components/multiline-text/multiline-text.js';
import { ResponsiveImage } from 'src/shared/components/responsive-image/responsive-image.js';
import type { Image } from 'src/shared/types/image.js';

import { cnBlockProfessions1Item } from './block-professions-1-item.constants.js';

import './block-professions-1-item.css';

import './__button/block-professions-1-item__button.css';
import './__content/block-professions-1-item__content.css';
import './__duration/block-professions-1-item__duration.css';
import './__image/block-professions-1-item__image.css';
import './__nearest-cohort/block-professions-1-item__nearest-cohort.css';
import './__title/block-professions-1-item__title.css';

type BlockAttributes = {
    title: string;
    duration: string;
    buttonTitle: string;
    buttonLink: string;
    image: Image;
    cohortDate: string | null;
    nextGroupStartsSoonText: string;
    joinBeforeStartDateText: string;
};

export interface BlockProfessions1ItemProps extends BlockAttributes {}

export const BlockProfessions1Item: FunctionalComponent<BlockProfessions1ItemProps> = ({
    title,
    duration,
    buttonTitle,
    buttonLink,
    image,
    cohortDate,
    nextGroupStartsSoonText,
    joinBeforeStartDateText,
}) => (
    <div className={cnBlockProfessions1Item({})}>
        <div className={cnBlockProfessions1Item('content')}>
            <div>
                <MultilineText className={cnBlockProfessions1Item('title')}>
                    <HtmlText html={title} />
                </MultilineText>
                <MultilineText className={cnBlockProfessions1Item('nearest-cohort')}>
                    {cohortDate ? `${joinBeforeStartDateText}\n${cohortDate}` : nextGroupStartsSoonText}
                </MultilineText>
                <MultilineText className={cnBlockProfessions1Item('duration')}>
                    <HtmlText html={duration} />
                </MultilineText>
            </div>
            <Button className={cnBlockProfessions1Item('button')} href={buttonLink} mode="dark">
                {buttonTitle}
            </Button>
        </div>
        <ResponsiveImage className={cnBlockProfessions1Item('image')} image={image} />
    </div>
);
